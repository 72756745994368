<template>
  <div class=''>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>My Contact Info</h1>
      </div>
    </portal>
    <div class='flex flex-row justify-start w-full bg-gray-100 pl-8 pr-8 py-4 text-sm'>
      <button class='border px-12 h-12 rounded-md font-bold hover:shadow-md defaultStyle' :disabled='invalidContact' @click='submitUpdate'>수정</button>
    </div>
    <contact-edit-fields class='pl-8' />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ContactEditFields from '@/components/contacts/ContactEditFields.vue'

export default {
  name: 'MyContact',
  components: {
    ContactEditFields
  },
  data () {
    return {
      logoURL: process.env.VUE_APP_CLIENT_BASE_URL + '/logo_reach_horizontal.svg'
    }
  },
  computed: {
    ...mapState('users', [
      'currentUserInfo'
    ]),
    ...mapState('contacts', [
      'myContact',
    ]),
    invalidContact () {
      return !!((this.myContact.fax_number && this.notNumRegex.test(this.myContact.fax_number)) ||
        (this.myContact.phone_number && this.notNumRegex.test(this.myContact.phone_number)) ||
        (this.myContact.office_phone_number && this.notNumRegex.test(this.myContact.office_phone_number)))
    },
    notNumRegex () {
      return /([^0-9-])/g
    },
  },
  methods: {
    ...mapActions('contacts', [
      'editContact',
      'getMyContact',
      'updateMyContact',
      'setEmptyContact'
    ]),
    submitUpdate () {
      this.updateMyContact(this.myContact)
    },
  },
  mounted () {
    this.getMyContact().then(() => this.editContact(this.myContact))
  },
  beforeDestroy () {
    this.setEmptyContact()
  }
}
</script>
